@import "../../../variables";
@import "../../../scss/breakpoints";

.containerRow {
  column-gap: 10px;
  display: grid;
  grid-auto-flow: column;
}

.title {
  text-decoration: none;
  user-select: none;
}

.title:hover {
  color: $white !important;
  text-decoration: none;
}

.searchInput {
  input {
    border: 1px solid $gray1;
  }
}

.link {
  color: $white !important;
}

.link:hover {
  color: $white !important;
  text-decoration: none;
}

.logo {
  fill: $white;
  margin-top: 5px;
  width: 200px;
}

@include media-breakpoint-down(sm) {
  .logo {
    display: none;
  }
}

@include media-breakpoint-down(md) {
  .logo {
    margin-left: 1rem;
  }
}
