@import "~@blueprintjs/core/lib/scss/variables";
$black: #1a1a1a;
$white: #f0f0f0;

$dark-gray1: #1f1f1f;
$dark-gray2: #292929;
$dark-gray3: #363636;
$dark-gray4: #404040;
$dark-gray5: #4a4a4a;

$light-gray1: #d6d6d6;
$light-gray2: #e0e0e0;
$light-gray3: #e8e8e8;
$light-gray4: #f0f0f0;
$light-gray5: #f7f7f7;

$gray1: #6e6e6e;
$gray2: #858585;
$gray3: #999;
$gray4: #b5b5b5;
$gray5: #cfcfcf;

$blue1: #046193;
$blue2: #0473ae;
$blue3: #0586cc;
$blue4: #06a4f9;
$blue5: #3db8fa;

$dark-blue: #122945;
//sizes
$pt-font-size-large: 18px;

//tooltip
$tooltip-background-color: $gray1;

// navbar
$dark-navbar-background-color: $dark-gray4;

//input
$dark-input-background-color: rgba($black, 0.1);

//datePicker
$dark-datepicker-background-color: $gray5;
$datepicker-background-color: $gray5;
$datepicker-day-background-color-hover: $blue3;
$datepicker-day-background-color-active: $light-gray1;
$dark-datepicker-day-background-color-hover: $blue3;
$dark-datepicker-day-background-color-active: $light-gray1;

//dateRangePicker
$daterangepicker-range-background-color: $light-gray4;
$daterangepicker-range-background-color-selected: $gray4;
$daterangepicker-range-background-color-selected-hover: $light-gray1;
$dark-daterangepicker-range-background-color: $light-gray4;
$dark-daterangepicker-range-background-color-selected: $gray4;
$dark-daterangepicker-range-background-color-selected-hover: $light-gray1;

//icon
$pt-dark-icon-color: $gray4;
$pt-icon-color: $gray1;

//card
$card-background-color: $light-gray2;

//button
$dark-button-background-color: $gray1;
$button-background-color: $light-gray1;

//tag
$tag-default-color: $dark-blue;

//colors aliases
$pt-intent-primary: $blue1;

//input
$input-background-color-disabled: rgba($gray5, 0.5) !default;

//font-family
$date-font-family: "Monaco";
$app-font-family: "Century";

//panel header
$panel-header-height: 40px;

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
);
