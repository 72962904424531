@import "../../../variables";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.message {
  color: $dark-gray4;
  margin-top: 10px;
}

.engine {
  animation-duration: 3s;
  animation-iteration-count: infinite;
  animation-name: engineSpin;
  animation-timing-function: linear;
  fill: $dark-gray4;
}

@keyframes engineSpin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
