@import "./variables";
@import "./scss/breakpoints";

.app {
  background: $light-gray5;
}

.loading-app {
  align-items: center;
  display: flex;
  height: 100vh;
  justify-content: center;
  width: 100vw;
}

.checbox-no-background {
  input:not(:checked) ~ .#{$ns}-control-indicator {
    background-color: transparent !important;
    background-image: none !important;
  }
}

.checbox-white-border {
  input:not(:checked) ~ .#{$ns}-control-indicator {
    box-shadow: inset 0 0 0 1px rgba(242, 242, 242, 0.6), inset 0 -1px 0 rgba(242, 242, 242, 0.1);
  }
}

.#{$ns}-dialog {
  background: $light-gray2 !important;

  .#{$ns}-dialog-header {
    background: $light-gray2 !important;
    box-shadow: 0 1px 5px rgba(26, 26, 26, 0.15) !important;
  }
}

.#{$ns}-form-group {
  .#{$ns}-label {
    font-weight: bold;
  }
}

.#{$ns}-datepicker {
  color: $dark-gray4;

  svg {
    fill: $gray1 !important;
  }

  /* stylelint-disable-next-line */
  .DayPicker-Day--disabled {
    color: rgba($color: $dark-gray4, $alpha: 0.3) !important;
  }

  .#{$ns}-timepicker {
    .#{$ns}-timepicker-input-row {
      background: rgba($color: $black, $alpha: 0.1) !important;

      /* stylelint-disable-next-line */
      input {
        color: $dark-gray4 !important;
      }
    }
  }
}

.button-submit {
  font-size: 1.2rem !important;
  margin-top: 2rem;
}

.sr-input {
  appearance: none;
  background: $white;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(4, 97, 147, 0), 0 0 0 0 rgba(4, 97, 147, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  color: $dark-gray1;
  height: 40px;
  padding: 5px 12px;
  transition: box-shadow 0.2s ease;
  width: 100%;
}

/* stylelint-disable-next-line */
.StripeElement--focus {
  box-shadow: 0 0 0 1px #046193, 0 0 0 3px rgba(4, 97, 147, 0.3), inset 0 1px 1px rgba(26, 26, 26, 0.2);
  outline: none;
  z-index: 9;
}

.sr-card-element {
  margin-top: 10px;
  padding-top: 10px;
}

.dateRangeDark {
  .#{$ns}-daterangepicker-shortcuts {
    background-color: $dark-datepicker-background-color;
  }
}

@include media-breakpoint-down(sm) {
  .dateRangeDark {
    flex-direction: column;
    .#{$ns}-control-group {
      flex-direction: column;
    }
  }
}

.PhoneInput {
  flex: 1;
}

.PhoneInputInput {
  background: #f0f0f0;
  border: unset;
  border-radius: 3px;
  box-shadow: 0 0 0 0 rgba(4, 97, 147, 0), 0 0 0 0 rgba(4, 97, 147, 0), inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  font-weight: 400;
  height: 40px;
  outline: none;
  padding: 0 10px;
  transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
  vertical-align: middle;
}
