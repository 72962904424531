@import "../../../variables";

.logo {
  fill: $white;
  width: 120px;
}

.menuItem {
  align-items: flex-start;
  align-items: center;
  border-radius: 2px;
  color: inherit;
  color: $white !important;
  column-gap: 10px;
  display: grid;
  grid-template-columns: 20px 1fr;
  line-height: 20px;
  padding: 10px 14px;
  text-decoration: none;
  user-select: none;
}

.menuItem:hover {
  background-color: rgba($color: $gray3, $alpha: 0.15);
  cursor: pointer;
  text-decoration: none;
}
