@import "../../../variables";

.buttonContainer {
  position: relative;
}

.button {
  border: 1px solid $gray1 !important;
  height: $pt-grid-size * 3;
  width: $pt-grid-size * 3;
}

.badgeContainer {
  position: absolute;
  right: -13px;
  top: -9px;
}

.positionTooltip {
  z-index: 30;
}

.badgeContainer {
  position: absolute;
  right: 3px;
  top: 2px;
  width: 10px;
  height: 10px;
  background-color: $red3;
  border-radius: 50%;
}

.alertButton {
  border: 1px solid $gray1 !important;
  position: relative !important;
  height: $pt-grid-size * 3;
  width: $pt-grid-size * 3;
}
