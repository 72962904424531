@import "../../../variables";
@import "../../../scss/breakpoints";

.container {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  min-height: calc(100vh);
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  div {
    font-size: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    @include media-breakpoint-down(sm) {
      font-size: 1.2rem;
    }
  }
}

pre {
  white-space: pre-line;
}
