.input {
  input {
    border-radius: 3px !important;
    box-shadow: unset !important;
  }
}

.dummyElement {
  width: 30px;
  height: 30px;
}
