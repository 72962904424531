@import "../../../variables";
@import "../../../scss/breakpoints";

.container {
  background-color: rgba($color: $blue5, $alpha: 0.3);
  min-height: calc(100vh - #{$pt-navbar-height});
  display: flex;
  flex: 1;
  width: 100vw;
  flex-direction: column;
}

.sky {
  height: 250px;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.aircraft {
  animation-name: move;
  height: 100px;
  transform: rotate(90deg);
  animation-duration: 5s;
  position: absolute;
  animation-iteration-count: infinite;
  @include media-breakpoint-down(sm) {
    height: 50px;
  }
}

.dash {
  border-bottom-width: 7px;
  border-bottom-style: dashed;
  border-color: rgba($white, 0.2);
  width: 100%;
}

.children {
  display: flex;
  flex: 1;
  padding: 0 40px;
}

@keyframes move {
  0% {
    // left: -100px;
    left: 0px;
  }
  100% {
    left: 100%;
  }
}

.towerContainer {
  display: flex;
  justify-content: flex-end;
  margin-bottom: -30px;
  z-index: 2;
  padding-right: 100px;
  @include media-breakpoint-down(sm) {
    padding-right: 50px;
    margin-bottom: -10px;
  }
}

.road {
  background-color: $dark-gray5;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  @include media-breakpoint-down(sm) {
    height: 70px;
  }
}

.tower {
  height: 300px;
  @include media-breakpoint-down(md) {
    height: 200px;
  }
  @include media-breakpoint-down(sm) {
    height: 100px;
  }
}
