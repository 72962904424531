@font-face {
  font-family: "Century";
  src: url("./assets/fonts/CenturyGothic/CenturyGothic.ttf") format("ttf"),
    url("./assets/fonts/CenturyGothic/CenturyGothic.woff") format("woff"),
    url("./assets/fonts/CenturyGothic/CenturyGothic.woff2") format("woff2");
}

@import "~normalize.css";
@import "./variables";
@import "~@blueprintjs/core/src/blueprint";
@import "~@blueprintjs/datetime/src/blueprint-datetime";
@import "~@blueprintjs/popover2/src/blueprint-popover2";

html {
  font-size: 16px;
  margin: 0;
  min-height: 100vh;
  padding: 0;
}

body {
  font-family: "Century", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell",
    "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100vh;
  margin: 0;
  width: 100vw;
  color: $black;
}

a {
  color: $blue1;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

@media only screen and (max-width: 768px) {
  html {
    font-size: 14px;
  }

  body {
    font-size: 14px;
  }
}
